import React from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/form/fields/Radio';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const normalizeBoolean = (value) => {
  if (typeof value === 'boolean') return value;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value; 
};
const CustomRadioSelect = ({ input: { onChange, value }, classes, values, parseValue = v => v, parseLabel = v => v }) => {
  const normalizedValue = normalizeBoolean(value);
  return <div className={classes.Outliers}>
    { values.map(v => <label key={parseValue(v)} className={classes.label}>
      <Radio
        value={parseValue(v)}
        checked={normalizedValue === parseValue(v)}
        onChange={onChange}
        className={classes.radio}
      />
      <span className={classes.labelText} children={parseLabel(v)} />
    </label>) }
  </div>;
};

CustomRadioSelect.propTypes = {
  classes: PropTypes.shape({
    Outliers: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radio: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
  }).isRequired,
  parseValue: PropTypes.func,
  parseLabel: PropTypes.func,
  values: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(CustomRadioSelect);
