import React, { useState } from 'react';
import injectSheet from 'lib/sheet';
import PropTypes from 'prop-types';
import styles from './sheet.js';

const FileUpload = ({ input, meta, classes,...props }) => {
  const [fileName, setFileName] = useState('');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      input.onChange(file);
      setFileName(file.name);
      e.target.value = '';  // Reset the input value
    }
  };

  return (
    <div style={{ display: 'flex', gap: 5, }} className={classes.FileUpload}>
      <input
        type="file"
        {...props}
        onChange={handleFileChange}
        className={classes.uploadInput}
      />
      {fileName && input.value && input.value!=="" && (
          <span style={{ width: '100%', paddingTop: '2px', fontWeight: 300 }}>{fileName}</span>
      )}
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
  );
};
FileUpload.propTypes = {
    classes: PropTypes.shape({
      fileUpload: PropTypes.string.isRequired,
    }).isRequired,
  };
export default injectSheet(styles)(FileUpload);
