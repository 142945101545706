import React from 'react';
import { createItemPage } from 'pages/common/ItemPage';

import { actions, selectors } from 'domain/smcTag';

import { columns } from 'fields/smcTag';
import { createSimpleForm } from 'pages/common/Form';
import { createFormConfig } from 'lib/helpers';

import IndexComp from './IndexComp';
import PropTypes from 'prop-types';

const { item } = selectors;

export const editableFields = ['fullName', 'smcTag', 'lowCarValue', 'carValue', 'highCarValue', 'defaultCarField'];
export const indexFields = ['mgvEnabled', 'period', 'csvFile'];
export const combinedFields = [...editableFields, ...indexFields];

const index = (item) => <IndexComp items={item} />;

index.propTypes = {
  index: PropTypes.any,
};
export default createItemPage({
  name: 'smctag',
  titleFn: (item) => item.get('fullName'),
  connections: {
    item,
    updateItemAction: actions.updateItemAction,
  },
  config: {
    basicInfo: [
      createFormConfig(columns.select(...editableFields)),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
    index,
    
  },
  Form: createSimpleForm({
    form: 'smctagForm',
    action: actions.updateItemAction,
  }),
});
