import {
  connectAcceptance,
  commonDictionaryFields,
  defaultCarField,
  createIdLink,
  toFloat,
} from 'fields/common';
import { defineColumns } from 'components/PageTable/defineColumns';
import rules from 'components/form/validation';
import CustomRadioSelect from 'components/form/fields/CustomRadioSelect';
import FileUpload from 'components/form/fields/CSVUpload';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('smcTag'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  smcTag: { sortable: true, className: 'td300', inlineEdit: true, },
  fullName: {
    sortable: true,
    className: 'name',
    inlineEdit: true,
    fieldProps: { validate: [ rules.required ] },
  },
  mgvEnabled: {
    hiddenForTable: true,
    inlineEdit: true,
    component: CustomRadioSelect,
    fieldProps: { values: ['ENABLE', 'DISABLE'],
      parseValue: (v) => v=='ENABLE'? true :  false,
     },
  },
  period: {
    hiddenForTable: true,
    inlineEdit: true,
    component: CustomRadioSelect,
    fieldProps: { values: ['Annual', 'Semi Annual'], parseValue: v => v == 'Annual' ? 'annual' : 'semiannual' },
  },
  lowCarValue: { getValue: toFloat, inlineEdit: true, sortable: true, className: 'td200', },
  carValue: { getValue: toFloat, inlineEdit: true, sortable: true, className: 'td200', },
  highCarValue: { getValue: toFloat, inlineEdit: true, sortable: true, className: 'td200', },
  csvFile: {
    hiddenForTable: true,
    inlineEdit: true,
    title: ' ',
    className: 'fileUploadComp',
    component: FileUpload,
    fieldProps: { accept: '.csv' },
  },
  defaultCarField,
  ...commonDictionaryFields
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns};

export default columns;
