import axiosOrigin from 'axios';
import { select as createSelector } from 'cpcs-reconnect';
import { call, select, put, takeEvery } from 'redux-saga/effects';

import { dataToCase, toCamel, toSnake } from 'lib/helpers';
import { addNotification, checkAuth } from "./env";

let axios = axiosOrigin;

const excludeList = [
  { url: 'coupons', method: 'patch' },
  { url: 'coupons', method: 'post' },
  { url: 'enterprise', method: 'patch' },
  { url: 'enterprise', method: 'post' },
];

if (process.env.NODE_ENV === 'test') {
  axios = require('lib/testUtils').axiosMock;
} else {
  axios.interceptors.response.use(({ data, ...props }) => {
    if (props.headers['content-type'] === 'application/pdf'){
      return { data, ...props };
    }
    if (data && data.current_action && (data.intersect_actions || data.normal_actions)) {
      return { data, ...props };
    }
    return { ...props, data: dataToCase(data, toCamel) };
  });
  axios.interceptors.request.use(({ data, params, ...props }) => {
    if (!!excludeList
      .find(({ url, method }) => props.url.includes(url) && method === props.method)
    ) return { data, params, ...props };
    return {
      ...props,
      data: ( data instanceof FormData ) ? data : dataToCase(data, toSnake),
      params: dataToCase(params, toSnake),
    };
  });
}

/**
 * this selector was created without importing 'domain/env' module
 * to avoid cyclic importing
**/
const tokenSelector = createSelector(state => state.env.getIn(['token']));

/**
 * @arg api enpoint fn
 * @arg arg is map { query: {} or '', data: {}, ...urlNamedParams }
 * @arg params - axios({ ..., ...params, headers: { ..., ...(params.headers || {})}})
 *
 * examples:
 *     const { data: { id: artistId } } = yield callApi(Api.addArtist, { data: payload });
 *     yield callApi(Api.getNavigation, { query, model, id });
**/
export function* callApi(api, arg = {}, params) {
  const token = yield select(tokenSelector);
  try {
    /**
     * calls api with ({ token, query = '', ...props }, params = {})
     * where props is map with url parts like id in '/my/url/{id}'
    **/
    return yield call(api, { ...arg, token }, params);
  } catch (e) {
    const status = e?.response?.data?.status || e?.response?.status;
    if (status === 404) {
      /**
       * this action type was used as string literal without importing model actions
       * to avoid cyclic importing
      **/
      console.error(e)
      yield put({ type: 'ui/ERROR/404', payload: true });
      return;
    } else if (status === 401) {
      yield put({ type: checkAuth.failure });
      yield put(addNotification({ type: 'error', title: 'Token has expired, please auth again' }));
      return;
    }
    throw e;
  }
}

const API_PREFIX = '/api/v1/';

const search = /{\w+}/g;
const checkUrlAndParams = (url, params) => {
  try {
    url = url.replace(search, v => {
      const value = params[v.slice(1,-1)];
      delete params[v.slice(1,-1)];
      return value;
    });
  } catch (e) {
    if (process.env.NODE_ENV === 'test') {
      throw new Error('can`t build url ' + url);
    }
  }
  return ({ url: `${API_PREFIX}${url}`, ...params });
};

const addPureRequest = ( url, method = 'post' ) => ({ query = '', data, ...urlNamedParams }, params = {}) => {
  return axios({
    ...params,
    query,
    data,
    method,
    ...checkUrlAndParams(url, urlNamedParams),
  });
};

// istanbul-ignore-next
const addSecuredRequest = ( url, method = 'get' ) => (
  {
    token,
    query = '',
    data,
    ...urlNamedParams
  },
  /**
   * axios props (optional):
   *   paramsSerializer
   *   headers
   *   onUploadProgress
   *   onDownloadProgress
   *   maxContentLength
   *   ...
  **/
  params = {},
) => {
  const headers = params.headers || {};
  return axios({
    method,
    params: query,
    data,
    ...checkUrlAndParams(url, urlNamedParams),
    ...params,
    headers: { 'Authorization': `JWT ${token}`, ...headers },
  });
};


/**
 * naming rules!!!
 * bulk actions assume that all api methods named in same rules:
 *   let ModelName = 'JunkCharacter';
 *   GET    | get    + ModelName: apiFn('/api/endpoint/')
 *   PATCH  | update + ModelName: apiFn('/api/endpoint/{modelNameId}', 'patch')
 *   POST   | add    + ModelName: apiFn('/api/endpoint/', 'post')
 *   DELETE | remove + ModelName: apiFn('/api/endpoint/', 'delete')
 *   getJunkCharacterList: addSecuredRequest('spider/junk_characters'),
 *   updateJunkCharacter: addSecuredRequest('spider/junk_characters/{junkCharacterId}', 'patch'),
 *   addJunkCharacter: addSecuredRequest('spider/junk_characters/', 'post'),
 *   removeJunkCharacter: addSecuredRequest('spider/junk_characters/', 'delete'),
**/
const Api =  {
  login: addPureRequest('token-auth/'),
  forgotPassword: addPureRequest('password/reset/'),
  resetPassword: addPureRequest('password/reset/confirm/'),
  refreshToken: addPureRequest('token-refresh'),
  getUserData: addSecuredRequest('profile/', 'get'),
  getArtistsList: addSecuredRequest('artexpert/artists/'),
  getArtistsListCSV: addSecuredRequest('artexpert/artist/load_csv_file'),
  getArtworksList: addSecuredRequest('artexpert/arts'),
  getArtworksListCSV: addSecuredRequest('artexpert/art/load_csv_file'),
  getIntersectionsListCSV: addSecuredRequest('artexpert/rtv/clusters/{artworkId}/{action}/load_csv_file'),
  getLotsList: addSecuredRequest('artexpert/auction_lots/'),
  getLotsListCSV: addSecuredRequest('artexpert/auction_lot/load_csv_file'),
  getLotPrices: addSecuredRequest('artexpert/auction_lots/{lotId}/scraped_sold_prices'),
  searchAuthors: addSecuredRequest('artexpert/search/artists'),
  getAuthor: addSecuredRequest('artexpert/search/artists/{artistId}'),
  getArtist: addSecuredRequest('artexpert/artists/{artistId}'),
  getArtistRtv: addSecuredRequest('artexpert/artists/{artistId}/recalculate_rtv'),
  runArtistRtv: addSecuredRequest('artexpert/artists/{artistId}/recalculate_rtv', 'patch'),
  runArtistAeAbvUpdate: addSecuredRequest('artexpert/artists/{artistId}/update_ae_abv', 'post'),
  runArtistWebAbvSync: addSecuredRequest('artexpert/artists/{artistId}/sync_web_abv', 'post'),
  getArtwork: addSecuredRequest('artexpert/arts/{artworkId}'),
  getLastRtv: addSecuredRequest('artexpert/arts/{id}/ae_rtv'),
  getSimilarArtworks: addSecuredRequest('artexpert/arts/{artworkId}/similarity'),
  getClusterList: addSecuredRequest('artexpert/rtv/clusters/{artworkId}/{action}'),
  getNavigation: addSecuredRequest('artexpert/navigation/{model}/{id}'),
  mergeArtwork: addSecuredRequest('artexpert/arts/{artworkId}/merge/{id}', 'patch'),
  addArtist: addSecuredRequest('artexpert/artists', 'post'),
  addLot: addSecuredRequest('artexpert/auction_lots/', 'post'),
  updateLot: addSecuredRequest('artexpert/auction_lots/{lotId}/', 'patch'),
  removeLot: addSecuredRequest('artexpert/auction_lots/{lotId}/', 'delete'),
  removeArtist: addSecuredRequest('artexpert/artists/{artistId}', 'delete'),
  updateArtist: addSecuredRequest('artexpert/artists/{artistId}', 'patch'),
  addArtwork: addSecuredRequest('artexpert/arts', 'post'),
  removeArtwork: addSecuredRequest('artexpert/arts/{artworkId}', 'delete'),
  updateArtwork: addSecuredRequest('artexpert/arts/{artworkId}', 'patch'),
  pushUpdate: addSecuredRequest('artexpert/arts/{id}/abv_push_update', 'patch'),
  profile: addSecuredRequest('profile/'),
  getHistory: addSecuredRequest('artexpert/{modelName}/{modelId}/history/'),
  uploadImage: addSecuredRequest('artexpert/art_images/', 'post'),
  // Dictionaries
  getDictionaryItem: addSecuredRequest('artexpert/{name}/{id}'),
  getCountries: addSecuredRequest('countries?page_size=300'),
  getAuctions: addSecuredRequest('artexpert/auctions/'),
  getConditions: addSecuredRequest('artexpert/conditions/'),
  getCategories: addSecuredRequest('artexpert/categories/'),
  getSubjects: addSecuredRequest('artexpert/subjects/'),
  getMediums: addSecuredRequest('artexpert/medium3d'),
  getSubstrates: addSecuredRequest('artexpert/substrates/'),
  getSurfaces: addSecuredRequest('artexpert/surfaces/'),
  getSubstrateValues: addSecuredRequest('artexpert/substrate_values/'),
  getSurfaceValues: addSecuredRequest('artexpert/surface_values/'),
  getCurrencies: addSecuredRequest('currencies?page_size=500'),
  // Junk characters
  getJunkCharacterList: addSecuredRequest('spider/junk_characters'),
  updateJunkCharacter: addSecuredRequest('spider/junk_characters/{junkCharacterId}', 'patch'),
  addJunkCharacter: addSecuredRequest('spider/junk_characters/', 'post'),
  removeJunkCharacter: addSecuredRequest('spider/junk_characters/{junkCharacterId}', 'delete'),
  // Subjects
  getSubjectList: addSecuredRequest('artexpert/subjects/'),
  getSubject: addSecuredRequest('artexpert/subjects/{id}'),
  // SmcTag
  getSmctagList: addSecuredRequest('artexpert/car_tags'),
  getSmctag: addSecuredRequest('artexpert/car_tag_data/{id}/'),
  addSmctag: addSecuredRequest('artexpert/car_tags/', 'post'),
  removeSmctag: addSecuredRequest('artexpert/car_tags/{id}', 'delete'),
  updateSmctag: addSecuredRequest('artexpert/car_tag_data/{id}/', 'put'),
  // Substrates
  getSubstrateList: addSecuredRequest('artexpert/substrates/'),
  getSubstrate: addSecuredRequest('artexpert/substrates/{id}'),
  addSubstrate: addSecuredRequest('artexpert/substrates/', 'post'),
  updateSubstrate: addSecuredRequest('artexpert/substrates/{id}', 'patch'),
  removeSubstrate: addSecuredRequest('artexpert/substrates/{id}', 'delete'),
  // 3D Medium
  getMediumList: addSecuredRequest('artexpert/medium3d/'),
  getMedium: addSecuredRequest('artexpert/medium3d/{id}'),
  addMedium: addSecuredRequest('artexpert/medium3d/', 'post'),
  updateMedium: addSecuredRequest('artexpert/medium3d/{id}', 'patch'),
  removeMedium: addSecuredRequest('artexpert/medium3d/{id}', 'delete'),
  // Surfaces
  getSurfaceList: addSecuredRequest('artexpert/surfaces/'),
  getSurface: addSecuredRequest('artexpert/surfaces/{id}'),
  addSurface: addSecuredRequest('artexpert/surfaces/', 'post'),
  updateSurface: addSecuredRequest('artexpert/surfaces/{id}', 'patch'),
  removeSurface: addSecuredRequest('artexpert/surfaces/{id}', 'delete'),
  // Auctions
  getAuctionList: addSecuredRequest('artexpert/auctions/'),
  getAuction: addSecuredRequest('artexpert/auctions/{id}'),
  addAuction: addSecuredRequest('artexpert/auctions/', 'post'),
  updateAuction: addSecuredRequest('artexpert/auctions/{id}', 'patch'),
  removeAuction: addSecuredRequest('artexpert/auctions/{id}', 'delete'),
  // Auction Sales
  getSaleList: addSecuredRequest('artexpert/auction_sales/'),
  getSale: addSecuredRequest('artexpert/auction_sales/{id}'),
  addSale: addSecuredRequest('artexpert/auction_sales/', 'post'),
  updateSale: addSecuredRequest('artexpert/auction_sales/{id}', 'patch'),
  removeSale: addSecuredRequest('artexpert/auction_sales/{id}', 'delete'),
  // Customer
  getCustomerList: addSecuredRequest('webapp/users'),
  getCustomerListCSV: addSecuredRequest('webapp/users/load_csv_file'),
  getCustomer: addSecuredRequest('webapp/users/{id}'),
  updateCustomer: addSecuredRequest('webapp/users/{id}', 'patch'),
  removeCustomer: addSecuredRequest('webapp/users/{id}', 'delete'),
  // Enterprise
  getEnterpriseList: addSecuredRequest('webapp/enterprises'),
  getEnterprise: addSecuredRequest('webapp/enterprises/{id}'),
  addEnterprise: addSecuredRequest('webapp/enterprises', 'post'),
  updateEnterprise: addSecuredRequest('webapp/enterprises/{id}', 'patch'),
  removeEnterprise: addSecuredRequest('webapp/enterprises/{id}', 'delete'),
  // Spider Runner
  getRunner: addSecuredRequest('spider/spider_runners/{id}'),
  getRunnerList: addSecuredRequest('spider/spider_runners'),
  runnerAction: addSecuredRequest('spider/spider_runners/{id}/{action}', 'patch'),
  // portfolio
  getPortfolioList: addSecuredRequest('webapp/portfolios'),
  // Spider Sessions
  getSession: addSecuredRequest('spider/spider_sessions/{id}'),
  getSessionList: addSecuredRequest('spider/spider_sessions'),
  // Spider Items
  getItemList: addSecuredRequest('spider/spider_items'),
  getItem: addSecuredRequest('spider/spider_items/{id}'),
  // Spider Arts
  getScrappedList: addSecuredRequest('spider/art_items'),
  getScrapped: addSecuredRequest('spider/art_items/{id}'),
  updateScrapped: addSecuredRequest('spider/art_items/{id}', 'patch'),
  updateArtItem: addSecuredRequest('spider/art_items/{id}/update', 'patch'),
  // Coupon
  getCouponList: addSecuredRequest('webapp/coupons'),
  getCoupon: addSecuredRequest('webapp/coupons/{id}'),
  addCoupon: addSecuredRequest('webapp/coupons', 'post'),
  updateCoupon: addSecuredRequest('webapp/coupons/{id}', 'patch'),
  // webAppArts
  getWebAppRtv: addSecuredRequest('artexpert/arts/{id}/all_rtv'),
  getArtList: addSecuredRequest('webapp/artworks/'),
  getArtListCSV: addSecuredRequest('webapp/artworks/load_csv_file'),
  getCustomerArtList: addSecuredRequest('webapp/users/{userId}/artworks/'),
  getCustomerArtListCSV: addSecuredRequest('webapp/users/{userId}/artworks/load_csv_file'),
  getArt: addSecuredRequest('webapp/artworks/{id}'),
  // Alerts
  getAlertList: addSecuredRequest('webapp/alerts/'),
  getAlertListCSV: addSecuredRequest('webapp/alerts/load_csv_file'),
  getCustomerAlertList: addSecuredRequest('webapp/users/{userId}/alerts/'),
  getCustomerAlertListCSV: addSecuredRequest('webapp/users/{userId}/alerts/load_csv_file'),
  getAlert: addSecuredRequest('webapp/alerts/{id}'),
  // Purchases
  getPurchaseList: addSecuredRequest('webapp/purchase/'),
  getCustomerPurchaseList: addSecuredRequest('webapp/users/{userId}/purchases/'),
  getCustomerPurchasesListCSV: addSecuredRequest('webapp/users/{userId}/purchases/load_csv_file'),
  getPurchase: addSecuredRequest('webapp/purchase/{id}'),
  getPurchasePdf: addSecuredRequest('webapp/users/purchases/{id}'),
  getSecuredPurchasePdf: addSecuredRequest('webapp/users/purchases_pdf/{id}'),
  pushAbv: addSecuredRequest('webapp/users/purchases_push_abv/{id}', 'post'),
  // webAppConsiderations
  getConsiderationList: addSecuredRequest('webapp/considerations/'),
  getConsiderationListCSV: addSecuredRequest('webapp/considerations/load_csv_file'),
  getCustomerConsiderationList: addSecuredRequest('webapp/users/{userId}/considerations/'),
  getCustomerConsiderationListCSV: addSecuredRequest('webapp/users/{userId}/considerations/load_csv_file'),
  getConsideration: addSecuredRequest('webapp/considerations/{id}'),
  // sub requests
  getRTV: addSecuredRequest('artexpert/data/art/{artworkId}/{action}'),
  getRTVActions: addSecuredRequest('artexpert/rtv/art/actions'),
  getAwLots: addSecuredRequest('artexpert/arts/{artworkId}/auction_lots/'),
  //trial
  getTrial: addSecuredRequest('webapp/trial_plan'),
  updateTrial: addSecuredRequest('webapp/trial_plan', 'patch'),
  //artCluster
  getAoCluster: addSecuredRequest('artexpert/arts/{id}/cluster'),
  updateAoCluster: addSecuredRequest('artexpert/arts/{id}/cluster', 'patch'),
  addAoCluster: addSecuredRequest('artexpert/arts/{id}/cluster', 'post'),
  getAoClusterList: addSecuredRequest('artexpert/rtv/clusters/{id}/customer_manifold_clusters/group'),
  getClusterAoList: addSecuredRequest('artexpert/rtv/clusters/{artworkId}/customer_manifold_clusters/number/{id}'),
  // artist car data
  getArtistCarData: addSecuredRequest('artexpert/artists/{artistId}/car_data'),
  // /api/v1/artexpert/artists/1019/car_data_graph/all
  getArtistCarCharts: addSecuredRequest('artexpert/artists/{artistId}/car_data_graph/{period}'),
  
  // indexes published data
  getTableX001: addSecuredRequest('index/x001'),
  getTableX002: addSecuredRequest('index/x002'),
  getTableX003: addSecuredRequest('index/x003'),
  addPublishX001: addSecuredRequest('index/publish/x001', 'post'),
  addCalculateX001: addSecuredRequest('index/calculate/x001', 'post'),
  addPublishX002: addSecuredRequest('index/publish/x002', 'post'),
  addCalculateX002: addSecuredRequest('index/calculate/x002', 'post'),
  addPublishX003: addSecuredRequest('index/publish/x003', 'post'),
  addCalculateX003: addSecuredRequest('index/calculate/x003', 'post'),
  addGenerateQuarterly: addSecuredRequest('index/generate/quarterly', 'post'),
  addGenerateSemiannual: addSecuredRequest('index/generate/semiannual', 'post'),
  getTableX004: addSecuredRequest('artexpert/artists/distribution/all_annual_quintile_index_view/{liq_ind}/{year}'),
  addPublishX004: addSecuredRequest('artexpert/artists/distribution/all_annual_quintile_index_publish'),
  addCalculateX004: addSecuredRequest('artexpert/artists/distribution/all_annual_quintile_index_calculation'),
};

export default Api;

require('promise/lib/rejection-tracking').enable();
let apiStore;
export const initApiStore = v => apiStore = v;
/**
 * @param apiName - key from Api predefined endpoints
**/
export const callApiAction = apiName => {
  if (!apiName) throw new Error('callApiAction apiName not set');
  if (!Api[apiName]) throw new Error(`callApiAction apiName ${apiName} not found`);
  if (!apiStore) throw new Error('callApiAction init apiStore first');
  return ({ data, query, urlParams, headers, options } = {}) => {
    return new Promise((resolve, reject) => {
      apiStore.dispatch({
        type: 'CALL_API_ACTION',
        payload: {
          resolve, reject,
          apiFn: Api[apiName],
          // POST PUT PATCH request data
          data,
          // URL params: `?foo={query.foo}&...`
          query,
          // named url params: { id } for '/my/url/{id}'
          urlParams,
          // pass extra headers
          headers,
          // axios options
          options,
        },
      });
    });
  };
};

function* onCallApiAction({ payload }) {
  const { apiFn, data, query, urlParams, headers, resolve, reject, options = {} } = payload;
  try {
    const response = yield call(callApi, apiFn, { query, data, ...urlParams }, { headers, ...options });
    if (!response) {
      reject(new Error(404));
      return;
    }
    resolve(response);
  } catch(err) {
    reject(err);
  }
}

export function* watchCallApiAction() {
  yield takeEvery('CALL_API_ACTION', onCallApiAction);
}
