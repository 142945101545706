export default {
  fileUpload: {
    
  },
  uploadInput: {
    width: 112,
    color: 'transparent',
    '&::before': {
      content: '"Upload"',
      color: 'black',
      display: 'inline-block',
      background: '#ffffff',
      border: '1px solid #999',
      borderRadius: '3px',
      padding: '5px 8px',
      outline: 'none',
      whiteSpace: 'nowrap',
      WebkitUserSelect: 'none',
      cursor: 'pointer',
      textShadow: '1px 1px #fff',
      fontWeight: 400,
      fontSize: '14px',
      width: 94,
      textAlign: 'center',
    },
    '&:hover::before': {
      borderColor: 'black',
    },

    '&::-webkit-file-upload-button': {
      visibility: 'hidden',
    },
  },
};
