import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const SimpleTable = ({ tableData, smctag }) => (
  <div style={{ overflowX: 'auto', marginTop: '14px' }}>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ borderBottom: '2px solid #e01b24', padding: '8px', paddingRight: '30px', paddingLeft: 0, paddingTop: 0, textAlign: 'right', color: '#000000' }}>Date</th>
          <th style={{ borderBottom: '2px solid #e01b24', padding: '8px', paddingTop: 0, textAlign: 'right', color: '#e01b24' }}>{smctag}</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index}>
            <td style={{ padding: '8px', borderBottom: '1px solid #d3d3d3', textAlign: 'left', }}>{moment(row?.date, 'MM-DD-YYYY').format('MM/DD/YYYY')}</td>
            <td style={{ padding: '8px', borderBottom: '1px solid #d3d3d3', textAlign: 'left', }}>{row?.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

SimpleTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      date: PropTypes.number.isRequired,
    })
  ).isRequired,
};

const IndexTable = ({indexData, smctag}) => {
    return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <h3 style={{ width: '165px', fontSize: '14px', fontWeight: 400, color: '#000000' }}>Index Data</h3>
      <SimpleTable tableData={indexData} smctag={smctag}/>
  </div>)
  };

export default IndexTable;
