import React from 'react';
import PropTypes from 'prop-types';

import sheet from 'components/PageTable/sheet';
import injectSheet from 'lib/sheet';
import ValuesList from 'components/ValuesList';
import columns from 'fields/smcTag';
import { createFormConfig } from 'lib/helpers';
import IndexTable from './IndexTable';

const IndexPage = (props) => {
  const {items, classes} = props;
  return <div className={classes.indexContainer}>
    <ValuesList
      key={`tag-${items.id}`}
      config={createFormConfig(
        columns.select('csvFile'),
      )}
      {...props}
      rowData={items}
      modifier={'csvFile'}
    />
    <div style={{ marginTop: '8px' }}></div>
    <ValuesList
      key={`tag-${items.id}`}
      config={createFormConfig(
        columns.select('mgvEnabled', 'period'),
      )}
      {...props}
      rowData={items}
    />
    <div style={{ marginBottom: '12px' }}></div>
    {items && items?.indexData && items?.indexData?.size > 0 && <IndexTable indexData={items.indexData} smctag={items.smcTag} />}
  </div>
};

IndexPage.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.any,
};

export default injectSheet({
  ...sheet,
  DataTable: {
    overflow: 'auto',
    flex: '1 1 100%',
  },
})(IndexPage);
