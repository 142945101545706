import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { Route, Switch, withRouter, Redirect } from 'react-router';
import cx from 'classnames';
import { getLocationName as routeName_sel } from 'domain/router/RouterModel';
import { isAuthorized, authChecked, userProfile } from 'domain/env';
import { get404 } from 'domain/ui';
import { settings } from 'domain/settings';
import { compose } from 'redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import I from 'immutable';
import BulkActionsIndicator from 'components/BulkActionsIndicator';

import { links as r, lnk } from 'lib/routes';
import injectSheet from 'lib/sheet';
import sheet from 'theme/main';

import Menu from 'components/Menu/Menu';
import Loader from 'components/Loader';

import Notifications from 'components/notifications';
import Tooltip from 'components/Tooltip';
import NotFoundPage from 'pages/NotFoundPage';

import getItems from "components/Menu/items";
import AclRoute from 'components/AclRoute';
import FormLoader from "../components/FormLoader";

function App({ classes, settings, error, userProfile, isAuthorized, authChecked, routeName }) {

  const { items, isSingle } = React.useMemo(() => getItems(userProfile), [ userProfile ]);

  const firstLinkName = React.useMemo(() => {
    if (items.length === 0) return '/';
    return isSingle ? items[0].linkName : items[0].children[0].linkName
  }, [ isSingle, items ]);

  const renderPages = React.useCallback(() => {
    if (error) return (
      <div className={classes.container}>
        <Menu isSingle={isSingle} items={items} userProfile={userProfile} />
        <NotFoundPage/>
      </div>
    );

    const redirectLnk = lnk(firstLinkName, { query: settings.getIn([firstLinkName,'query'], new I.Map()).toJS() });

    return (
      <div className={classes.container}>
        <BulkActionsIndicator/>
        <FormLoader/>
        <Menu isSingle={isSingle} items={items} userProfile={userProfile} />
        <Switch>
          <AclRoute path={r.artist} component={require('pages/ArtistPage').default} />
          <AclRoute path={r.artistList} component={require('pages/ArtistListPage').default} />
          <AclRoute exact path={r.artwork} component={require('pages/ArtworkPage').default}/>
          <AclRoute exact path={r.artworksSimilarList} component={require('pages/ArtworkSimilarPage').default} />
          <AclRoute exact path={r.artworkIntersectionsList} component={require('pages/ArtworkIntersectionsPage').default} />
          <AclRoute path={r.artworkList} component={require('pages/ArtworkListPage').default}/>
          <AclRoute path={r.lotList} component={require('pages/LotListPage').default}/>
          <AclRoute path={r.auction} component={require('pages/AuctionPage').default}/>
          <AclRoute path={r.auctionList} component={require('pages/AuctionsListPage').default}/>
          <AclRoute path={r.subject} component={require('pages/SubjectPage').default}/>
          <AclRoute path={r.subjectList} component={require('pages/SubjectsListPage').default}/>
          <AclRoute path={r.substrate} component={require('pages/SubstratePage').default}/>
          <AclRoute path={r.substrateList} component={require('pages/SubstratesListPage').default}/>
          <AclRoute path={r.surface} component={require('pages/SurfacePage').default}/>
          <AclRoute path={r.surfaceList} component={require('pages/SurfacesListPage').default}/>
          <AclRoute path={r.medium} component={require('pages/MediumPage').default}/>
          <AclRoute path={r.mediumList} component={require('pages/MediumListPage').default}/>
          <AclRoute path={r.sale} component={require('pages/AuctionSalePage').default}/>
          <AclRoute path={r.saleList} component={require('pages/AuctionSalesListPage').default}/>
          <AclRoute path={r.customer} component={require('pages/CustomerPage').default}/>
          <AclRoute path={r.customerList} component={require('pages/CustomersListPage').default}/>
          <AclRoute path={r.enterprise} component={require('pages/EnterprisePage').default}/>
          <AclRoute path={r.enterpriseList} component={require('pages/EnterpriseListPage').default}/>
          <AclRoute path={r.consideration} component={require('pages/ConsiderationPage').default}/>
          <AclRoute path={r.considerationList} component={require('pages/ConsiderationsListPage').default}/>
          <AclRoute path={r.art} component={require('pages/WebAppArtworkPage').default}/>
          <AclRoute path={r.artList} component={require('pages/WebAppArtworkListPage').default}/>
          <AclRoute path={r.alertList} component={require('pages/AlertListPage').default}/>
          <AclRoute path={r.purchaseList} component={require('pages/PurchaseListPage').default}/>
          <AclRoute path={r.coupon} component={require('pages/CouponPage').default}/>
          <AclRoute path={r.couponList} component={require('pages/CouponsListPage').default}/>
          <AclRoute path={r.runner} component={require('pages/RunnerPage').default}/>
          <AclRoute path={r.runnerList} component={require('pages/RunnerListPage').default}/>
          <AclRoute path={r.portfolioList} component={require('pages/PortfolioListPage').default} />
          <AclRoute path={r.item} component={require('pages/ItemPage').default}/>
          <AclRoute path={r.itemList} component={require('pages/ItemListPage').default}/>
          <AclRoute path={r.session} component={require('pages/SessionPage').default}/>
          <AclRoute path={r.junkCharacterList} component={require('pages/JunkCharacterListPage').default}/>
          <AclRoute path={r.sessionList} component={require('pages/SessionListPage').default}/>
          <AclRoute path={r.scrapped} component={require('pages/ScrappedPage').default}/>
          <AclRoute path={r.scrappedList} component={require('pages/ScrappedListPage').default}/>
          <AclRoute path={r.smcTag} component={require('pages/SmcTagPage').default}/>
          <AclRoute path={r.smcTagList} component={require('pages/SmcTagListPage').default}/>
          <AclRoute path={r.settings} component={require('pages/SettingsPage').default}/>
          <AclRoute path={r.clusterList} component={require('pages/ClusterListPage').default}/>
          <AclRoute path={r.artworksByCluster} component={require('pages/ArtworkClustersPage').default}/>
          <AclRoute path={r.published} component={require('pages/PublishedPage').default}/>
          <Redirect from="/" exact to={redirectLnk} />
        </Switch>
      </div>
    );
  }, [ isSingle, settings, classes, items, userProfile, firstLinkName, error ]);

  const renderAuthPages = React.useCallback(() => {
    return (
      <Switch>
        <Route path={r.login} component={require('pages/LoginPage').default} />
        <Route path={r.forgot} component={require('pages/ForgotPage').default} />
        <Route path={r.resetPassword} component={require('pages/ResetPage').default} />
        <Redirect to={r.login} />
      </Switch>
    );
  }, []);

  if (authChecked) {
    return (
      <div className={cx(classes.mainContainer, routeName)}>
        { isAuthorized ? renderPages() : renderAuthPages() }
        <Notifications />
        <Tooltip />
      </div>
    );
  } else {
    return (
      <div className={cx(classes.mainContainer, routeName)}><Loader/></div>
    );
  }
}

App.propTypes = {
  authChecked: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  eventDispatcher: PropTypes.shape({
    addEventListener: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
  settings: PropTypes.instanceOf(I.Map),
  error: PropTypes.bool,
  routeName: PropTypes.string,
};

export default compose(
  withRouter,
  connect({
    routeName: routeName_sel,
    isAuthorized,
    authChecked,
    settings,
    error: get404,
    userProfile,
  }),
  injectSheet(sheet),
  DragDropContext(HTML5Backend),
)(App);
