import { matchPath } from 'react-router-dom';
import Route from './Route';
import { getLinks } from './helpers';
import { push as _routerPush } from 'connected-react-router';

const routes = [
  new Route('home', '/'),
  new Route('login', '/auth/login'),
  new Route('profile', '/profile'),
  new Route('forgot', '/auth/forgot'),
  new Route('resetPassword', '/auth/reset-password'),
  new Route('artistList', '/ae/artists'),
  new Route('artist', '/ae/artists/:artistId'),
  new Route('artworkList', '/ae/artworks', { exact: true }),
  new Route('artworksSimilarList', '/ae/artworks/:artworkId/similar', { exact: true } ),
  new Route('artworkIntersectionsList', '/ae/artworks/:artworkId/intersections/:action', { exact: true } ),
  new Route('artwork', '/ae/artworks/:artworkId'),
  new Route('lotList', '/ae/lots'),
  new Route('auction', '/ae/auctions/:id'),
  new Route('auctionList', '/ae/auctions'),
  new Route('subject', '/ae/subjects/:id'),
  new Route('subjectList', '/ae/subjects'),
  new Route('surface', '/ae/surfaces/:id'),
  new Route('surfaceList', '/ae/surfaces'),
  new Route('medium', '/ae/mediums/:id'),
  new Route('mediumList', '/ae/mediums'),
  new Route('substrate', '/ae/substrates/:id'),
  new Route('substrateList', '/ae/substrates'),
  new Route('sale', '/ae/sales/:id'),
  new Route('saleList', '/ae/sales'),
  new Route('customer', '/wa/users/:id'),
  new Route('customerList', '/wa/users'),
  new Route('art', '/wa/artworks/:id'),
  new Route('artList', '/wa/:userId?/artworks'),
  new Route('alert', '/wa/alerts/:id'),
  new Route('alertList', '/wa/:userId?/alerts'),
  new Route('purchaseList', '/wa/:userId?/purchases'),
  new Route('consideration', '/wa/considerations/:id'),
  new Route('considerationList', '/wa/:userId?/considerations'),
  new Route('coupon', '/wa/coupons/:id'),
  new Route('couponList', '/wa/coupons'),
  new Route('runner', '/spiders/runners/:id'),
  new Route('runnerList', '/spiders/runners'),
  new Route('portfolioList', '/ae/portfolios'),
  new Route('junkCharacterList', '/spiders/junk-characters'),
  new Route('item', '/spiders/items/:id'),
  new Route('itemList', '/spiders/items'),
  new Route('session', '/spiders/sessions/:id'),
  new Route('sessionList', '/spiders/sessions'),
  new Route('published', '/indexes/published'),
  new Route('scrapped', '/spiders/arts/:id'),
  new Route('scrappedList', '/spiders/arts'),
  new Route('smcTag', '/ae/smc-tag/:id'),
  new Route('smcTagList', '/ae/smc-tag'),
  new Route('settings', '/wa/settings'),
  new Route('enterprise', '/wa/enterprises/:id'),
  new Route('enterpriseList', '/wa/enterprises'),
  new Route('clusterList', '/ae/clusters/:id'),
  new Route('artworksByCluster', '/ae/cluster-aos/:artworkId/:id')
];

const hashTable = routes.reduce((collected, r) => ({ ...collected, [r.name]: r }), {});

export const links = getLinks(hashTable);

export const lnk = (routeName, params) => {
  if (!hashTable[routeName]) {
    console.error(new Error(`Route "${routeName}" not found `));
    return '/';
  }
  return hashTable[routeName].build(params);
};

export const routerPush = (...args) => _routerPush(lnk(...args));

export const matchRoute = (locationPathname) => {
  for (let route of routes) {
    const match = matchPath(locationPathname, {
      exact: ('exact' in route) ? route.exact : true,
      strict: false,
      path: route.link,
    });
    if (match !== null) {
      return { match, route };
    }
  }
  return null;
};
